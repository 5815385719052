<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterBrand
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.brand"/>
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterGudang
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.gudang"
                    v-model:distributor="state.params.distributor"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.url"
                    :params="state.params"
                    namefile="Laporan-Log-Stok-Gudang-Distributor"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.url"
                :params="state.params"
                :columns="state.columns"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue'
import TableStandard from '@/components/Molecules/TableStandard'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterBrand from '@/components/filter/FilterBrand'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        TableStandard,
        DownloadExcel,
        FilterDistributor,
        FilterGudang,
        FilterBrand,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'kode_gudang',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'nama_gudang',
                },
                {
                    title: 'Brand',
                    dataIndex: 'brand',
                },
                {
                    title: 'Volume Stok',
                    dataIndex: 'volume_stock',
                },
            ],
            url: '/api/report/metabase-v2/log-stok-gudang-distributor',
            isFetching: false,
            params: {
                q: '',
                distributor: [],
                gudang: [],
                brand: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
                page: 1,
                "per-page": 10,
            },
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const btnFetchData = async () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            state.params = pickBy(params)

            state.isFetching = true
            await nextTick()
            state.isFetching = false
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        margin-top: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>
